/*
 * @Author: your name
 * @Date: 2021-08-17 09:53:06
 * @LastEditTime: 2021-08-17 09:54:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\user\api\user.js
 */
import { get, post } from "../../../api/requests";

export const getList = data => {
  return get("user/list", data);
};

export const edit = data => {
  return post("user/edit", data);
};
