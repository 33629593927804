/*
 * @Author: your name
 * @Date: 2021-08-17 09:57:56
 * @LastEditTime: 2021-08-17 09:58:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\user\api\role.js
 */
import { get } from "../../../api/requests";

export const getList = () => {
  return get("role/list");
};
